$white: rgba(241, 241, 241, 1);
$dark: rgba(26, 34, 44, 1);
$red: rgba(224, 85, 90, 1);

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/roboto-v18-latin-regular.woff2') format('woff2'),
    url( '../fonts/roboto-v18-latin-regular.woff') format('woff');
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Roboto", Verdana, Helvetica, Arial, sans-serif;
  outline: none;
  background-color: $white;
  color: $dark;
}

* {
  box-sizing: border-box;
}

a {
  color: $dark;
  text-decoration: none;
  transition: color 0.2s ease;
  cursor: pointer;
}

a:hover {
  color: $red;
}

h1 {
  color: $red;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  padding: 1rem;
  margin: 2rem auto;

  .profile {
    text-align: center;
    h1 {
      margin-bottom: 1rem;
    }

    img {
      width: 10rem;
      height: 10rem;
      border-radius: 150px;
      border: 1px $white solid;
    }
  }

  .links {
    display: flex;
    justify-content: space-around;
    padding: 0.75rem 0.5rem;
    background-color: $dark;
    color: $white;
    border-radius: 0.4rem;
    margin-bottom: 1rem;

    .section {
      display: flex;
      flex-direction: column;

      a {
        color: $white;

        &:hover {
          color: $red;
        }
      }
    }
  }

  .date {
    text-align: center;
  }
}
